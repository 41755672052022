/* General Body and HTML Setup */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  font-family: 'Roboto', Arial, sans-serif;
}

/* App Container */
.App {
  text-align: center;
  color: #000;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url('assets/img/image1.png'); 
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Parallax effect */
}

/* Hero Section */
.hero-section {
  position: relative;
  color: white;
  text-align: center;
  padding: 10px 5px; /* Reduced padding for smaller image */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 20vh; /* Further reduced height */
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(245, 245, 245, 0.623); /* Thinner overlay */
  z-index: 1;
}

.hero-section .content {
  position: relative;
  z-index: 2;
}

/* Header Overlay */
.header-overlay {
  position: relative;
  z-index: 1;
}

/* Header Top */
.header-top {
  display: flex;
  color: #000;
  justify-content: center;
  align-items: center;
  padding: 5px 10px; /* Further reduced padding */
}

/* Header Text */
.header-text {
  font-size: 1.8em; /* Reduced size for balance */
  font-weight: bold;
  margin-right: 5px; /* Reduced margin */
}

/* Header Logo */
.header-logo {
  width: 120px; /* Further reduced logo size */
  height: auto;
}

/* Header Bottom */
.header-bottom {
  color: #222;
  margin-top: 5px; /* Reduced space between elements */
  text-align: center; /* Center align text */
}

/* Header Titles */
.header-bottom h1 {
  margin: 0;
  font-size: 1.5em;
  font-weight: bold;
}

.header-bottom h2 {
  margin: 0;
  font-size: 1.2em;
  font-weight: bold;
}

/* Content Section */
.content {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  max-width: 1400px;
  margin: auto;
  flex-grow: 1;
  background: transparent;
  flex-wrap: wrap; /* Wrap content on smaller screens */
}

/* Columns */
.column {
  width: 30%; /* Adjusted width for more content space */
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  font-family: 'Roboto', Arial, sans-serif; /* Unified font across all tables */
}

/* Section */
.section {
  background: white;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
}

/* Column Images */
.column-image {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

/* Section Headers */
.section h3 {
  margin: 0;
  font-size: 1.8em;
  font-weight: 600;
  color: #2F4F4F; /* Dark slate gray */
  text-align: center; /* Center align headers */
}

/* Section Paragraphs */
.section p {
  margin: 10px 0;
  flex-grow: 1;
  color: #333333; /* Dark text color for good readability */
  line-height: 1.5em;
  text-align: left; /* Align text evenly across the width */
}

/* Contact Section */
.contact-section {
  padding: 40px;
  background-color: #f8f8f8;
}

/* Contact Form Container */
.contact-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

/* Contact Header */
.contact-header {
  text-align: left;
  width: 100%;
  max-width: 600px;
}

/* Heading Bar */
.heading-bar {
  width: 50px;
  height: 5px;
  background-color: #4d4d4d; 
  margin-bottom: 20px;
}

/* Contact Header Title */
.contact-header h1 {
  font-size: 3em;
  font-weight: bold;
  color: #333;
}

/* Contact Content */
.contact-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

/* Form */
form {
  width: 60%;
}

form label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.1em;
}

form input, form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}

/* Form Button */
form button {
  padding: 10px 20px;
  background-color: #4d4d4d; 
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1em;
}

form button:hover {
  background-color: #333; 
}

/* Message Sent */
.message-sent {
  color: green;
  margin-top: 10px;
}

/* Contact Info */
.contact-info {
  width: 35%;
  font-size: 1.1em;
}

.contact-info p {
  margin-bottom: 20px;
}

.contact-info a {
  color: #333;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

/* Footer */
.App-footer {
  background-color: #f1f1f1;
  color: black;
  padding: 10px 0;
  text-align: center;
  width: 100%;
}

.App-footer p {
  margin: 5px 0;
}

/* Mobile-Specific Styles */
@media (max-width: 768px) {
  .hero-section {
    height: 100vh; /* Revert to full screen height on mobile */
    padding: 60px 20px;
  }

  .hero-section::before {
    background-color: rgba(255, 255, 255, 0.6); /* Increased opacity for more contrast */
  }

  .content {
    flex-direction: column;
    padding: 20px 10px;
  }

  .column {
    width: 100%;
  }

  .header-text {
    font-size: 2.5em;
  }

  .header-logo {
    width: 200px;
  }

  .header-bottom h1 {
    font-size: 2em;
  }

  .header-bottom h2 {
    font-size: 1.8em;
  }

  form, .contact-info {
    width: 100%;
  }

  .contact-content {
    flex-direction: column;
  }
}
